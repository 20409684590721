import { template as template_fb7b8140276c4a498ed78f59c1036cd4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fb7b8140276c4a498ed78f59c1036cd4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
