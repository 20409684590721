import { template as template_7e82226f6769482f96874b01999179de } from "@ember/template-compiler";
const FKLabel = template_7e82226f6769482f96874b01999179de(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
