import { template as template_0b9998eaa5774aaaa326de13ab0bbc41 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_0b9998eaa5774aaaa326de13ab0bbc41(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
