import { template as template_9ee83f7227144eb7a2ff9c5aa76b1605 } from "@ember/template-compiler";
const FKText = template_9ee83f7227144eb7a2ff9c5aa76b1605(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
